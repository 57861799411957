.dados-linha {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.fornecedor {
    flex: 2;
}

.valor {
    flex: 1;
}

.borda-vermelha {
    border: 2px solid red !important;
    border-radius: 4px;
}
